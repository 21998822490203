
import axios from 'axios'

const speakerQuery = `{documents(modelId:%20%221d2a1ab3-2655-4bfe-59b2-08d8395b9458%22,%20count:50){documents{id,tagList,fieldValues},linkedDocuments{key,value{fieldValues}},totalDocuments}}`
const speakerUrl = `https://advaniaconferenceweb.azurewebsites.net/graphql?query=${speakerQuery}`

const breakoutQuery = `{documents(modelId:%20%22c4deb793-7016-4d7f-1e8a-08d850bfd991%22,%20count:50){documents{id,tagList,fieldValues},linkedDocuments{key,value{fieldValues}},totalDocuments}}`
const breakoutUrl = `https://advaniaconferenceweb.azurewebsites.net/graphql?query=${breakoutQuery}`

export const getSpeakers = () => {
	return axios.get(speakerUrl)
	.then(response => {
		//console.log(response.data.data.documents.documents)
		return response.data.data.documents.documents.map(x => x.fieldValues)
	})
}

export const getSideSpeakers = () => {
	return axios.get(breakoutUrl)
	.then(response => {
		return response.data.data.documents.documents.map(x => x.fieldValues);
	})
}